import { routes, SelectableUser, selectIdentity, UserAccountsRepsonse } from '~/utils';
import { SearchResult } from './QuickSearch';
import { useHistory } from 'react-router-dom';
import { Icon } from '~/components';
import { useQueryPersistance } from '~/hooks/use-query-persistance';
import { useEffect, useMemo, useState } from 'react';
import { sortBy, uniqBy } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { CommandItem } from '../Command';
import MiniSearch from 'minisearch';
import { MiniSearchConfig, defaultSearchConfig } from '~/utils';

type AccountResult = {
  orgName: string;
  qualifiedUser: string;
  orgID: string;
};

export function useAccountSearch(): SearchResult<AccountResult> {
  const history = useHistory();
  const { clearAllItemsFromStorage } = useQueryPersistance();

  const [accounts, setAccounts] = useState<SelectableUser[]>([]);

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_URL || ''}/auth/identity`, {
      method: 'GET',
      cache: 'no-cache',
      redirect: 'manual',
      credentials: 'include',
      referrerPolicy: 'no-referrer'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        const identities = data as UserAccountsRepsonse;
        const { memberOf, supportOrgs = [] } = identities;

        setAccounts(
          uniqBy([...sortBy(memberOf, 'orgName'), ...sortBy(supportOrgs, 'orgName')], 'orgName')
        );
      });
  }, []);

  const config: MiniSearchConfig<AccountResult> = {
    ...defaultSearchConfig,
    idField: 'orgID',
    fields: ['orgID', 'orgName'],
    storeFields: ['orgID', 'orgName', 'qualifiedUser']
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(accounts);
    return mini;
  }, [accounts]);

  const client = useApolloClient();

  function handleIdentitySelection(identity: string) {
    client.resetStore();
    clearAllItemsFromStorage();
    selectIdentity(identity, async () => {
      history.replace(routes.dashboard);
      location.reload();
    });
  }

  return {
    title: 'Workspaces',
    search: search => index.search(search) as unknown as AccountResult[],
    render: (account, handleSelect) => (
      <CommandItem
        key={account.orgName}
        className="flex items-center gap-2"
        onSelect={handleSelect(() => {
          handleIdentitySelection(account.qualifiedUser);
        })}
      >
        <Icon name="Team" className="text-gray-500" />
        <span>{account.orgName}</span>
      </CommandItem>
    )
  };
}
