import { useQuery } from '@apollo/client';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { ModelDocument } from '~/generated/graphql';
import { defaultSearchConfig, MiniSearchConfig, routes } from '~/utils';
import { CommandItem } from '../Command';
import { SearchResult } from './QuickSearch';

type ModelResult = {
  id: string;
  name: string;
  connectionTypeId: string;
  connectionName: string;
};

export function useModelSearch(): SearchResult<ModelResult> {
  const history = useHistory();
  const { data } = useQuery(ModelDocument);

  const config: MiniSearchConfig<ModelResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'name', 'connectionTypeId'],
    storeFields: ['id', 'name', 'connectionTypeId', 'connectionName']
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      (data?.model?.fieldsets ?? []).map<ModelResult>(model => ({
        id: model.id,
        name: model.name,
        connectionName: model.connection?.name,
        connectionTypeId: model.connection?.type?.id
      }))
    );
    return mini;
  }, [data]);

  return {
    title: 'Models',
    search: search => index.search(search) as unknown as ModelResult[],
    render: (model, handleSelect) => (
      <CommandItem
        key={model.id}
        value={model.id}
        onSelect={handleSelect(() =>
          history.push(generatePath(routes.editModel, { fieldsetId: model.id }))
        )}
        className="flex w-full min-w-0 items-center gap-2"
      >
        <Icon match={model.connectionTypeId} />
        <Truncator content={model.connectionName}>
          <span className="min-w-[50px] max-w-fit shrink-0 grow basis-0 truncate">
            {model.connectionName}
          </span>
        </Truncator>
        <Icon name="Disclosure" className="text-gray-500" />
        <Truncator content={model.name}>
          <span className="max-w-fit grow truncate">{model.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
