import { useQuery } from '@apollo/client';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { BulkSyncsDocument } from '~/generated/graphql';
import { MiniSearchConfig, routes, defaultSearchConfig } from '~/utils';
import { SearchResult } from '..';
import { CommandItem } from '../Command';

type BulkSyncResult = {
  id: string;
  name: string;
  sourceConnectionTypeId: string;
  sourceConnectionTypeName: string;
  destinationConnectionTypeId: string;
  destinationConnectionTypeName: string;
};

export function useBulkSyncsSearch(): SearchResult<BulkSyncResult> {
  const history = useHistory();
  const { data } = useQuery(BulkSyncsDocument);

  const config: MiniSearchConfig<BulkSyncResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'name', 'sourceConnectionTypeName', 'destinationConnectionTypeName'],
    storeFields: [
      'id',
      'name',
      'sourceConnectionTypeId',
      'sourceConnectionTypeName',
      'destinationConnectionTypeId',
      'destinationConnectionTypeName'
    ]
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      (data?.bulkSyncs ?? []).map<BulkSyncResult>(sync => ({
        id: sync.id,
        name: sync.name,
        sourceConnectionTypeId: sync.source?.connection?.type?.id,
        sourceConnectionTypeName: sync.source?.connection?.type?.name,
        destinationConnectionTypeId: sync.destination?.connection?.type?.id,
        destinationConnectionTypeName: sync.destination?.connection?.type?.name
      }))
    );
    return mini;
  }, [data]);

  return {
    title: 'Bulk syncs',
    search: search => index.search(search) as unknown as BulkSyncResult[],
    render: (sync, handleSelect) => (
      <CommandItem
        key={sync.id}
        value={sync.id}
        onSelect={handleSelect(() => history.push(generatePath(routes.bulkSync, { id: sync.id })))}
        className="flex items-center gap-2"
      >
        <div className="flex items-center gap-1">
          <Icon match={sync.sourceConnectionTypeId} />
          <Icon name="Syncs" />
          <Icon match={sync.destinationConnectionTypeId} />
        </div>
        <Truncator content={sync.name}>
          <span className="truncate">{sync.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
