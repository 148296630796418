import cx from 'clsx';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MyInput } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Operation, SyncFragment } from '~/generated/graphql';
import { useSanitizeIdText } from '~/hooks';
import {
  CREATE_TARGET_SCHEMA,
  SyncConfigFormValues,
  getSchemaHierarchy,
  getSchemaNormalized,
  splitMappings
} from '~/utils';
import { AdvancedSettings } from './advanced-settings';
import { StageCard } from './stage-card';
import { SyncSummary } from './sync-summary';

interface Props {
  sync: SyncFragment;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  newParentName: string;
  setNewParentName: React.Dispatch<React.SetStateAction<string>>;
  newTargetName: string;
  setNewTargetName: React.Dispatch<React.SetStateAction<string>>;
  parentLabel: string | undefined;
}

export function StageSummary(props: Props) {
  const { control, register, getValues, setValue, watch } = useFormContext<SyncConfigFormValues>();

  const targetConnection = useWatch({ control, name: 'targetConnection' });
  const targetObject = useWatch({ control, name: 'targetObject' });
  const mode = useWatch({ control, name: 'mode' });
  const identity = useWatch({ control, name: 'identity' });
  const schedule = useWatch({ control, name: 'schedule' });
  const mappings = useWatch({ control, name: 'mappings' });

  const hasInlineConfig = !!targetConnection?.type.operations.includes(
    Operation.DestinationRequireConfiguration
  );
  const isTargetCreator = !!targetObject.properties?.targetCreator;
  const isDynamicTarget = !!targetConnection?.type.destinationDataArchitecture;

  const { parent, child } = getSchemaHierarchy(targetConnection?.type.destinationDataArchitecture);
  const schema = getSchemaNormalized(targetConnection?.type.destinationDataArchitecture);
  const parentValue = parent ? watch(`targetSearchValues.${parent}`) : null;
  const parentKey = schema?.[parent]?.title?.toLocaleLowerCase() || 'schema';

  function getTargetObjectDisplay() {
    const targetObject = Object.assign({}, getValues('targetObject'));
    if (targetObject && isTargetCreator && props.newTargetName !== '') {
      if (parentValue) {
        if (parentKey === 'base') {
          targetObject.name = props.newTargetName;
        } else if (
          targetObject.connection.type.id === 'googleads' ||
          targetObject.connection.type.id === 'fbaudience'
        ) {
          targetObject.name = `${
            parentValue === CREATE_TARGET_SCHEMA
              ? props.newParentName
              : props.parentLabel || parentValue
          }: ${props.newTargetName}`;
        } else {
          targetObject.name = `${
            parentValue === CREATE_TARGET_SCHEMA
              ? props.newParentName
              : props.parentLabel || parentValue
          }.${props.newTargetName}`;
        }
      } else {
        targetObject.name = props.newTargetName;
      }
    }
    return targetObject;
  }

  return (
    <StageCard
      hasStickyHeader={true}
      step={5}
      header="Sync summary"
      className="px-6"
      footer={
        hasInlineConfig ? undefined : (
          <AdvancedSettings
            targetMode={targetObject.modes.find(m => m.mode === mode)}
            form={targetObject.advancedConfiguration}
            syncAllRecords={watch('syncAllRecords')}
            onChangeSyncAll={(syncAll: boolean) => {
              setValue('syncAllRecords', syncAll);
            }}
          />
        )
      }
    >
      <SyncSummary
        newTargetName={props.newTargetName}
        nameControls={
          <>
            {isDynamicTarget && parentValue === CREATE_TARGET_SCHEMA && (
              <>
                <p className="mb-4 self-center text-sm font-semibold text-gray-500">
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {schema?.[parent]?.title || 'Schema'} name
                </p>
                <CustomTextInput
                  type="parent"
                  value={props.newParentName}
                  setter={props.setNewParentName}
                  setIsDirty={props.setIsDirty}
                />
              </>
            )}
            {isTargetCreator && (
              <>
                <p className="mb-4 self-center text-sm font-semibold text-gray-500">
                  {targetObject.properties.newTargetNameLabel || schema?.[child]?.title || 'Table'}
                </p>
                <CustomTextInput
                  type="name"
                  value={props.newTargetName}
                  setter={props.setNewTargetName}
                  setIsDirty={props.setIsDirty}
                />
              </>
            )}
            <p className="self-center text-sm font-semibold text-gray-500">
              {isTargetCreator ? 'Sync name' : 'Name'}
            </p>
            <MyInput {...register('name')} />
          </>
        }
        sync={{
          ...props.sync,
          targetConnection,
          targetObject: getTargetObjectDisplay(),
          mode,
          identity,
          schedule,
          ...splitMappings(mappings)
        }}
        className={cx(!hasInlineConfig && 'last:!pb-6')}
      />
    </StageCard>
  );
}

interface CustomTextInputProps {
  type: 'parent' | 'name';
  value: string;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setter: React.Dispatch<React.SetStateAction<string>>;
}

function CustomTextInput(props: CustomTextInputProps) {
  const { getValues, setValue, getFieldState } = useFormContext<SyncConfigFormValues>();
  const { sanitizeIdText, sanitizeIdTextLoading } = useSanitizeIdText();

  const textRef = React.useRef<HTMLInputElement>(null);
  const prevTextRef = React.useRef<string>('');

  function updateState(value: string) {
    const targetConnection = getValues('targetConnection');
    props.setter(value);
    props.setIsDirty(true);
    if (props.type === 'name' && value !== '' && !getFieldState('name').isTouched) {
      setValue('name', cx(targetConnection?.name, value, 'sync'));
    }
  }

  async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const targetConnection = getValues('targetConnection');
    if (!targetConnection || !e.target.value || e.target.value === prevTextRef.current) {
      return;
    }
    updateState(e.target.value);
  }

  async function onBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const targetConnection = getValues('targetConnection');
    if (!targetConnection || !e.target.value || e.target.value === prevTextRef.current) {
      return;
    }
    const safeName = await sanitizeIdText(targetConnection.id, e.target.value);
    if (!safeName) {
      return;
    }
    prevTextRef.current = safeName;
    if (textRef.current) {
      textRef.current.value = safeName;
    }

    updateState(safeName);
  }

  return (
    <div className="relative">
      <MyInput
        ref={textRef}
        className="mb-4"
        name={`${props.type || 'custom'}-name`}
        onChange={onChange}
        onBlur={onBlur}
        disabled={sanitizeIdTextLoading}
        value={props.value}
      />
      {sanitizeIdTextLoading && (
        <div className="absolute top-3 right-2 flex items-center">
          <LoadingDots />
        </div>
      )}
    </div>
  );
}
