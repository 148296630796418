import { useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { ModelDocument } from '~/generated/graphql';
import { defaultSearchConfig, MiniSearchConfig, routes } from '~/utils';
import { CommandItem } from '../Command';
import { SearchResult } from './QuickSearch';

type TableResult = {
  id: string;
  name: string;
  connectionTypeId: string;
  connectionName: string;
  searchTerms: string;
};

export function useTableSearch(): SearchResult<TableResult> {
  const history = useHistory();
  const { data } = useQuery(ModelDocument);

  const config: MiniSearchConfig<TableResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'name', 'connectionTypeId', 'searchTerms'],
    storeFields: ['id', 'name', 'connectionTypeId', 'connectionName', 'searchTerms']
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      uniqBy(
        (data?.model?.fieldsets ?? [])
          .filter(table => table.searchTerms?.length === 1)
          .map<TableResult>(model => ({
            id: model.id,
            name: model.name,
            connectionName: model.connection?.name,
            connectionTypeId: model.connection?.type?.id,
            searchTerms: model.searchTerms?.join(' ')
          })),
        table => table.searchTerms
      )
    );
    return mini;
  }, [data]);

  return {
    title: 'Model tables',
    search: search => index.search(search) as unknown as TableResult[],
    render: (model, handleSelect) => (
      <CommandItem
        key={`modelTable-${model.searchTerms}`}
        value={`modelTable-${model.searchTerms}`}
        onSelect={handleSelect(() =>
          history.push(
            `${generatePath(routes.editModel, { fieldsetId: model.id })}?scrollToTable=true`
          )
        )}
        className="flex items-center gap-2"
      >
        <Icon match={model.connectionTypeId} />
        <div className="flex w-full min-w-0 items-center gap-1">
          <Truncator content={model.connectionName}>
            <span className="max-w-fit shrink-0 grow basis-0 truncate">{model.connectionName}</span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={model.name}>
            <span className="max-w-fit shrink-0 grow basis-0 truncate">{model.name}</span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={model.searchTerms}>
            <span className="max-w-fit grow truncate">{model.searchTerms}</span>
          </Truncator>
        </div>
      </CommandItem>
    )
  };
}
