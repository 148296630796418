import * as React from 'react';

import {
  EditableFieldsetFragment,
  EnrichmentUpdate,
  FieldsetConfiguration
} from '../generated/graphql';
interface FieldsetProviderProps {
  children?: React.ReactNode;
  fieldset: EditableFieldsetFragment;
  setFieldset: (fieldset: EditableFieldsetFragment) => void;
  applyUpdate: (
    update: Record<string, unknown>,
    options?: { refresh?: boolean; resetFields?: boolean; refreshConfiguration?: boolean },
    enrichments?: EnrichmentUpdate[]
  ) => void;
  loading: boolean;
  loadingEnrichments: boolean;
  refreshing: boolean;
  updatedConfig: FieldsetConfiguration;
  setUpdatedConfig: (config: FieldsetConfiguration) => void;
  preventSave: boolean;
  setPreventSave: (value: boolean) => void;
  abortSignal: AbortSignal;
  abortQuery: () => void;
}

export const FieldsetContext = React.createContext<FieldsetProviderProps>(undefined);

export function FieldsetProvider({ children, ...props }: FieldsetProviderProps) {
  return <FieldsetContext.Provider value={props}>{children}</FieldsetContext.Provider>;
}

export function useFieldsetState() {
  const contextIsDefined = React.useContext(FieldsetContext);
  if (contextIsDefined == null) {
    throw new Error('useFieldset must be used within a FieldsetProvider');
  }
  return contextIsDefined;
}
