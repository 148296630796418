import cx from 'clsx';
import { useAtom } from 'jotai';
import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { Link, generatePath, useRouteMatch } from 'react-router-dom';
import { Button, Icon, Tooltip } from '~/components';
import { FieldsetListItemFragment } from '~/generated/graphql';
import { addLineBreaks, plural, routes } from '~/utils';
import { ModelsStateAtom } from './models';

interface FieldsetProps {
  fieldset: FieldsetListItemFragment;
}

export function FieldsetRow({ fieldset }: FieldsetProps) {
  // state
  const [modelsState, setModelsState] = useAtom(ModelsStateAtom);
  // hooks
  const editingField = useRouteMatch<{ fieldId: string }>({ path: routes.editField, exact: true });
  const editingModel = useRouteMatch<{ fieldsetId: string }>({
    path: routes.editModel,
    exact: true
  });
  // vars
  const isEditingFieldset = editingModel && fieldset.id === editingModel?.params?.fieldsetId;
  const isEditingField =
    (editingField && fieldset.fields.some(field => field.id === editingField?.params?.fieldId)) ||
    fieldset?.enrichments?.some(enrichment =>
      enrichment?.provider?.fields?.some(field => field.id === editingField?.params?.fieldId)
    );
  const isExpanded = !!modelsState.expandedFieldsetIds.includes(fieldset.id);

  // f(x)
  const toggleFieldset = () => {
    setModelsState(s => ({
      ...s,
      expandedFieldsetIds: s.expandedFieldsetIds.includes(fieldset.id)
        ? s.expandedFieldsetIds.filter(id => id !== fieldset.id)
        : [...s.expandedFieldsetIds, fieldset.id]
    }));
  };
  // effects
  useEffect(() => {
    if (isEditingField) {
      setModelsState(s => ({
        ...s,
        expandedFieldsetIds: [...s.expandedFieldsetIds, fieldset.id]
      }));
    }
  }, [fieldset.id, isEditingField, setModelsState]);

  const fieldsCount =
    fieldset.fields.length +
    fieldset.enrichments?.flatMap(e => e.provider.fields).filter(f => !!f.published).length;

  return (
    <section className="border-b border-gray-300 p-2" data-fieldset-id={fieldset.id}>
      <Link
        to={generatePath(routes.editModel, { fieldsetId: fieldset.id })}
        className={cx(
          'grid cursor-pointer grid-cols-[1fr,auto] gap-x-1 rounded px-4 py-1.5 hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
          isEditingFieldset ? 'items-center bg-indigo-100' : 'items-start'
        )}
      >
        <div>
          <h3 className="break-words text-base font-medium">{addLineBreaks(fieldset.name)}</h3>
          <div className="relative flex items-center">
            <h4 className="text-sm leading-5 text-gray-500">{fieldset.connection.name}</h4>
            {fieldset.relatedTo[0] && (
              <Tooltip
                placement="right"
                className="max-w-2xl"
                content={`${fieldset.relatedTo[0].from.fieldset.name}.${fieldset.relatedTo[0].from.label} → ${fieldset.relatedTo[0].to.fieldset.name}.${fieldset.relatedTo[0].to.label}`}
              >
                <span>
                  <Icon name="Link" className="ml-1.5 h-4 w-4 text-gray-400 hover:text-gray-800" />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="self-start p-2">
          {isEditingFieldset ? (
            <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
          ) : (
            <Icon match={fieldset.connection.type.id} size="lg" />
          )}
        </div>
      </Link>
      <Button className="ml-2" theme="ghost" size="mini" onClick={toggleFieldset}>
        <div className={cx('transform duration-150 ease-in-out', !isExpanded && '-rotate-90')}>
          <Icon name="SelectSingle" size="md" className="p-0" />
        </div>
        <span className="select-none text-xs font-medium uppercase tracking-widest">
          {fieldsCount} {plural('field', fieldsCount > 1)}
        </span>
      </Button>
      {isExpanded && (
        <ul className="animate-fadeIn">
          {sortBy(
            [
              ...fieldset.fields,
              ...fieldset.enrichments
                .flatMap(enrichment => enrichment.provider.fields)
                .filter(field => !!field.published)
            ],
            f => f.label.toLocaleLowerCase()
          ).map(field => {
            const isEditing = editingField?.params?.fieldId === field.id;
            return (
              <li key={field.id} data-field-id={field.id}>
                <Link
                  to={{
                    pathname: generatePath(routes.editField, {
                      fieldsetId: fieldset.id,
                      fieldId: field.id
                    })
                  }}
                  className={cx(
                    'flex w-full items-center justify-between rounded py-1.5 pl-6 pr-4 text-sm text-gray-800 hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
                    isEditing && 'bg-indigo-100'
                  )}
                >
                  {field.label}
                  {isEditing ? (
                    <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
                  ) : null}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
}
