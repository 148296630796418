import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Checkbox,
  DisabledSelect,
  EditPermission,
  MyCombobox,
  Section,
  SideBySide
} from '~/components';
import { SalesforceConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  findName,
  getSchemaAsList,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor } from '../components';

const modeConfiguration: ModeConfiguration<SalesforceConfiguration> = {
  modes: [
    { name: 'table', label: 'Object select' },
    { name: 'query', label: 'SOQL query' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      table: mode === 'table' ? conf.table : '',
      query: mode === 'query' ? conf.query : '',
      includeDeleted: conf.includeDeleted
    }
  }),
  reset: { configuration: { table: '', query: '', includeDeleted: false } }
};

const queryUpdateObj = (conf: SalesforceConfiguration | undefined) => ({
  configuration: {
    table: '',
    query: conf?.query || '',
    includeDeleted: conf?.includeDeleted || false
  }
});

export function SalesforceFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues, setValue, watch } = useFormContext<FieldsetFormValues>();

  const { modes, mode, handleMode } = useModeSwitcher<SalesforceConfiguration>(modeConfiguration);

  const table = useWatch({ control, name: 'configuration.table' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const defaultDoc = (fieldset?.configuration as SalesforceConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  const handleTableUpdate = React.useCallback(() => {
    applyUpdate(
      {
        table: getValues('configuration.table'),
        query: ''
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);
  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration')
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <EditPermission
            fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
          >
            <MyCombobox
              className="max-w-xs"
              value={{ label: modes[mode], value: mode }}
              options={objToSelectables(modes, true)}
              onChange={handleMode}
              isDisabled={loading}
            />
          </EditPermission>
          {fieldset?.configuration && fieldset.configurationSchema && mode === 'table' && (
            <div className="mt-3 w-full max-w-xs animate-fadeIn space-y-2">
              <EnumPicker
                item={findName(list, 'configuration.table')}
                onChange={handleTableUpdate}
              />
            </div>
          )}
          <EditPermission>
            <div className="mt-3">
              <Checkbox
                label={'Include deleted records'}
                checked={watch('configuration.includeDeleted')}
                onChange={e => {
                  setValue('configuration.includeDeleted', e.target.checked);
                }}
              />
            </div>
          </EditPermission>
        </SideBySide>
        {mode === 'query' && (
          <ModelQueryEditor
            heading="SOQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || loading}
          disabled={loading}
          refresh={table ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
