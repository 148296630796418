import { IdObj, Selectable } from './custom-types.util';
import { isLabeledObj, isNamedObj } from './predicates.util';

// react-select requires objects to have `value` and `label` properties
export function toSelectable<T extends IdObj>(obj: T): T & Selectable {
  if (isNamedObj(obj)) {
    return {
      ...obj,
      value: obj.id,
      label: obj.name
    };
  }
  if (isLabeledObj(obj)) {
    return {
      ...obj,
      value: obj.id,
      label: obj.label
    };
  }
  throw new Error(
    `Cannot convert to Selectable: object missing both 'name' and 'label' properties: ${JSON.stringify(
      obj,
      null,
      2
    )}`
  );
}

export function toSelectables<T extends IdObj>(objects: T[]): (T & Selectable)[] {
  return objects.map(toSelectable);
}

type nameAndId = { id?: string; name?: string };

export function sortByNameAndId(a: nameAndId, b: nameAndId) {
  const UNKNOWN = 'Unknown object:';
  const aName = a.name?.trim() ?? a.id ?? '';
  const bName = b.name?.trim() ?? b.id ?? '';
  if (aName.startsWith(UNKNOWN) && bName.startsWith(UNKNOWN)) {
    return aName.localeCompare(bName);
  }
  if (aName.startsWith(UNKNOWN)) {
    return -1;
  }
  if (bName.startsWith(UNKNOWN)) {
    return 1;
  }
  return aName.localeCompare(bName);
}

export function searchByNameAndId(obj: nameAndId, search: string) {
  return (
    obj.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
    obj.id?.toLowerCase()?.includes(search?.toLowerCase())
  );
}

export function truncateMiddle(text: string, boundary = 32) {
  const shouldTruncate = text.length >= boundary;
  const half = Math.floor(boundary / 2);
  const truncated = shouldTruncate ? `${text.slice(0, half - 1)}...${text.slice(-half + 1)}` : text;

  return {
    shouldTruncate,
    truncated
  };
}
