import { useQuery } from '@apollo/client';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { ConnectionsDocument } from '~/generated/graphql';
import { defaultSearchConfig, MiniSearchConfig, routes } from '~/utils';
import { SearchResult } from '..';
import { CommandItem } from '../Command';

type ConnectionResult = {
  id: string;
  name: string;
  typeId: string;
};

export function useConnectionsSearch(): SearchResult<ConnectionResult> {
  const history = useHistory();
  const { data } = useQuery(ConnectionsDocument);

  const config: MiniSearchConfig<ConnectionResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'name'],
    storeFields: ['id', 'name', 'typeId']
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      (data?.connections ?? []).map<ConnectionResult>(connection => ({
        id: connection.id,
        name: connection.name,
        typeId: connection.type?.id
      }))
    );
    return mini;
  }, [data]);

  return {
    title: 'Connections',
    search: search => index.search(search) as unknown as ConnectionResult[],
    render: (connection, handleSelect) => (
      <CommandItem
        key={connection.id}
        value={connection.id}
        onSelect={handleSelect(() =>
          history.push(generatePath(routes.editConnection, { id: connection.id }))
        )}
        className="flex items-center gap-2"
      >
        <Icon match={connection.typeId} />
        <Truncator content={connection.name}>
          <span className="truncate">{connection.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
