import { Options } from 'minisearch';

export type FlattenObjectKeys<T extends Record<string, unknown>, Key = keyof T> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${Key}.${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

export type MiniSearchConfig<T extends Record<string, unknown>> = Options & {
  fields: FlattenObjectKeys<T>[];
  storeFields: FlattenObjectKeys<T>[];
};

export const defaultSearchConfig: Partial<Options> = {
  searchOptions: {
    prefix: true,
    combineWith: 'AND',
    fuzzy: 0.2
  }
};
