import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon, Truncator } from '~/components';
import { ModelField, useModelFields } from '~/hooks';
import { defaultSearchConfig, MiniSearchConfig, routes } from '~/utils';
import { CommandItem } from '../Command';
import { SearchResult } from './QuickSearch';

type FieldResult = {
  id: string;
  label: string;
  sourceName: string;
  fieldsetId: string;
  fieldsetName: string;
  fieldsetConnectionName: string;
  fieldsetConnectionTypeId: string;
  enrichmentFieldsetConnectionTypeId: string;
};

export function useFieldSearch(): SearchResult<FieldResult> {
  const history = useHistory();
  const { modelFields } = useModelFields();

  const config: MiniSearchConfig<FieldResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'label', 'sourceName'],
    storeFields: [
      'id',
      'label',
      'sourceName',
      'fieldsetId',
      'fieldsetName',
      'fieldsetConnectionName',
      'fieldsetConnectionTypeId',
      'enrichmentFieldsetConnectionTypeId'
    ]
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      (modelFields as ModelField[]).map<FieldResult>(field => ({
        id: field.id,
        label: field.label,
        sourceName: field.sourceName,
        fieldsetId: field.fieldset?.id,
        fieldsetName: field.fieldset?.name,
        fieldsetConnectionName: field.fieldset?.connection?.name,
        fieldsetConnectionTypeId: field.fieldset?.connection?.type?.id,
        enrichmentFieldsetConnectionTypeId: field.enrichmentFieldset?.connection?.type?.id
      }))
    );
    return mini;
  }, [modelFields]);

  return {
    title: 'Model fields',
    search: search => index.search(search) as unknown as FieldResult[],
    render: (field, handleSelect) => (
      <CommandItem
        key={field.id}
        value={field.id}
        onSelect={handleSelect(() =>
          history.push(
            generatePath(routes.editField, {
              fieldId: field.id,
              fieldsetId: field.fieldsetId
            })
          )
        )}
        className="flex items-center gap-2"
      >
        <Icon match={field.enrichmentFieldsetConnectionTypeId ?? field.fieldsetConnectionTypeId} />
        <div className="flex w-full min-w-0 items-center gap-1">
          <Truncator content={field.fieldsetConnectionName}>
            <span className="max-w-fit shrink-0 grow basis-0 truncate">
              {field.fieldsetConnectionName}
            </span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={field.fieldsetName}>
            <span className="max-w-fit shrink-0 grow basis-0 truncate">{field.fieldsetName}</span>
          </Truncator>
          <Icon name="Disclosure" className="text-gray-500" />
          <Truncator content={field.sourceName}>
            <span className="max-w-fit grow truncate">{field.sourceName}</span>
          </Truncator>
        </div>
      </CommandItem>
    )
  };
}
