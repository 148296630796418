import { type ScheduleProps } from './SchedulePicker';
import { MyCombobox, ParamButton } from '../form-components';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useQuery } from '@apollo/client';
import { Frequency } from '~/generated/graphql';
import { graphql } from '~/gql';
import { GroupedHeading } from '~/utils';
import Checkbox from '../v2/inputs/Checkbox';

const SyncAfterQuery = graphql(`
  query SyncAfterQuery {
    bulkSyncs {
      id
      name
    }
    peekSyncs {
      id
      name
      TargetConnectionName
    }
  }
`);

interface SyncValue {
  __typename?: 'BulkSync' | 'SyncPeek';
  id: string;
  name: string;
  TargetConnectionName?: string;
}

interface Value {
  __typename: 'BulkSync' | 'Sync';
  id: string;
  name: string;
  TargetConnectionName?: string;
}

interface Option {
  __typename?: 'BulkSync' | 'Sync';
  label: string;
  value: string;
}

const toOption = (value: SyncValue | Value): Option => ({
  __typename: value.__typename === 'SyncPeek' ? 'Sync' : 'BulkSync',
  label: value.TargetConnectionName ? `${value.TargetConnectionName}: ${value.name}` : value.name,
  value: value.id
});

const DefaultValue: Value = { __typename: 'Sync', id: '', name: '' };

export function FrequencyRunafter({
  schedule,
  onScheduleChange,
  runAfterSyncs,
  onRunAfterSyncsChange,
  syncId
}: ScheduleProps) {
  const [isAdding, setAdding] = useState(runAfterSyncs.length === 0);
  const displayValues = isAdding ? [...runAfterSyncs, DefaultValue] : runAfterSyncs;

  const query = useQuery(SyncAfterQuery, {
    fetchPolicy: 'network-only',
    skip: schedule.frequency !== Frequency.Runafter
  });
  const exclude = [syncId, ...runAfterSyncs.map(value => value.id)];
  const options = query.loading
    ? { bulk: [], model: [] }
    : { bulk: query.data.bulkSyncs.map(toOption), model: query.data.peekSyncs.map(toOption) };
  const filteredOptions = {
    bulk: options.bulk.filter(opt => !exclude.includes(opt.value)),
    model: options.model.filter(opt => !exclude.includes(opt.value))
  };

  const handleAdd = () => setAdding(true);
  const handleChangeAt = (index: number) => (option: Option) => {
    setAdding(false);

    const modified = [...runAfterSyncs];
    modified.splice(index, 1, {
      __typename: option.__typename,
      id: option.value,
      name: option.label
    });
    onRunAfterSyncsChange(modified);
  };
  const handleRemoveAt = (index: number) => () => {
    const modified = [...runAfterSyncs];
    modified.splice(index, 1);
    onRunAfterSyncsChange(modified);

    // Force adding when no values are present
    setAdding(isAdding || runAfterSyncs.length - 1 <= 0);
  };
  const handleToggleRunAfterSuccessOnly = () => {
    onScheduleChange({ ...schedule, runAfterSuccessOnly: !schedule.runAfterSuccessOnly });
  };

  return (
    <section className="mt-6 space-y-2.5">
      {displayValues.map((value, index) => {
        const hasOptions = filteredOptions.bulk.length + filteredOptions.model.length > 0;
        const isLast = index === runAfterSyncs.length - 1;
        const isSelected = !(value.id === '');
        const isOnly = runAfterSyncs.length === 0;

        return (
          <div key={value.id || uuid()} className="flex items-center space-x-2">
            <MyCombobox
              isLoading={query.loading}
              components={{
                GroupHeading: ({ data }) => (
                  <GroupedHeading label={data.label} optionsCount={data.options.length} />
                )
              }}
              className="w-3/4"
              placeholder="Choose a sync..."
              options={[
                {
                  label: 'Model syncs',
                  options: filteredOptions.model
                },
                {
                  label: 'Bulk syncs',
                  options: filteredOptions.bulk
                }
              ]}
              value={
                options.model.find(option => option.value === value.id) ||
                options.bulk.find(option => option.value === value.id)
              }
              onChange={handleChangeAt(index)}
            />
            {!isOnly && (
              <ParamButton action="delete" className="mt-0.5" onClick={handleRemoveAt(index)} />
            )}
            {!isAdding && isLast && hasOptions && isSelected && (
              <ParamButton action="add" className="mt-0.5" onClick={handleAdd} />
            )}
          </div>
        );
      })}
      <div className="pt-3.5">
        <Checkbox
          label="Only run when all parent syncs succeed"
          checked={schedule.runAfterSuccessOnly}
          onChange={handleToggleRunAfterSuccessOnly}
        />
      </div>
    </section>
  );
}
