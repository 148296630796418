import { useMutation, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, generatePath, useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { EditPermission } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import Route404 from '~/components/v2/templates/Route404';
import { Action, SyncDocument, UserViewedSyncDocument } from '~/generated/graphql';
import { AclProvider } from '~/hooks';
import SyncConfig from '~/pages/syncs/sync-config/sync-config';
import SyncHistory from '~/pages/syncs/sync-history/sync-history';
import SyncNotifications from '~/pages/syncs/sync-notifications/sync-notifications';
import SyncStatus from '~/pages/syncs/sync-status/sync-status';
import { routes } from '~/utils';
import * as Sentry from '@sentry/react';

const Sync = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(SyncDocument, {
    skip: !id,
    variables: { id }
  });
  useEffect(() => {
    if (!!error) {
      Sentry.captureException(error, { extra: { gqlErrs: error.graphQLErrors } });
    }
  }, [error]);

  const [viewSync] = useMutation(UserViewedSyncDocument);

  useEffect(() => {
    viewSync({ variables: { id } });
  }, [id]);

  const sync = data?.sync;

  if (!sync || !id) {
    return <EmptyPage loading={loading} error={!!error} message="No such sync." />;
  }

  const navConf = [
    {
      label: 'Status and information',
      to: generatePath(routes.syncStatus, { id }),
      path: routes.syncStatus,
      slug: 'status'
    },
    {
      label: 'Configuration',
      to: generatePath(routes.syncConfig, { id }),
      path: routes.syncConfig,
      slug: 'config',
      permission: Action.Edit,
      permissionMessage: 'You do not have permission to edit this sync.'
    },
    {
      label: 'History',
      to: generatePath(routes.syncHistory, { id: id }),
      path: routes.syncHistory,
      slug: 'history'
    },
    {
      label: 'Error handling',
      to: generatePath(routes.syncErrorHandling, { id }),
      path: routes.syncErrorHandling,
      slug: 'error_handling'
    }
  ];

  return (
    <AclProvider value={sync.acl}>
      <Helmet title={sync?.name ? `${sync.name + ' | Polytomic'}` : 'Syncs | Polytomic'} />
      <PageLayout topNavHeading={sync?.name || ''} pageNavConf={navConf}>
        <Switch>
          <Route exact path={routes.syncStatus}>
            <SyncStatus key={`${id}-1`} sync={sync} />
          </Route>
          <Route exact path={routes.syncConfig}>
            <EditPermission fallback={<Redirect to={generatePath(routes.syncStatus, { id })} />}>
              <SyncConfig key={`${id}-2`} sync={sync} />
            </EditPermission>
          </Route>
          <Route exact path={routes.syncHistory}>
            <SyncHistory key={`${id}-3`} sync={sync} />
          </Route>
          <Route exact path={routes.syncErrorHandling}>
            <SyncNotifications key={`${id}-4`} />
          </Route>
          <Redirect exact from={routes.sync} to={routes.syncStatus} />
          <Route path="*">
            <Route404 />
          </Route>
        </Switch>
      </PageLayout>
    </AclProvider>
  );
};

export default Sync;
