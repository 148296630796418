import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Checkbox,
  DisabledSelect,
  EditPermission,
  Label,
  MyCombobox,
  Section,
  SideBySide
} from '~/components';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, Selectable, capsFirst, getSchemaNormalized } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { FieldsTable } from '../components';

export function PipedriveFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues, setValue, register } = useFormContext<FieldsetFormValues>();
  const streamId = useWatch({ control, name: 'configuration.streamID' });

  const schema = React.useMemo(
    () => getSchemaNormalized(fieldset?.configurationSchema),
    [fieldset?.configurationSchema]
  );

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset?.configurationSchema && schema && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <div>
                <Label>{schema?.streamID?.title}</Label>
                <Controller
                  control={control}
                  name="configuration.streamID"
                  render={({ field }) => (
                    <EditPermission
                      fallback={
                        <DisabledSelect
                          valueLabel={field.value ? capsFirst(field.value) : undefined}
                        />
                      }
                    >
                      <MyCombobox
                        value={
                          field.value
                            ? {
                                value: field.value,
                                label: capsFirst(field.value)
                              }
                            : null
                        }
                        options={(schema?.streamID?.enum as unknown as Selectable[]) || []}
                        onChange={option => {
                          if (option == null) {
                            return;
                          }
                          field.onChange(option.value);
                          // clear primaryKey value as it will error on a different selection
                          setValue('primaryKey', null, {
                            shouldDirty: true
                          });
                          applyUpdate({
                            ...getValues('configuration'),
                            streamID: option.value
                          });
                        }}
                      />
                    </EditPermission>
                  )}
                />
              </div>
              {streamId === 'deals' && schema?.includeDeleted?.title && (
                <div>
                  <EditPermission>
                    <Checkbox
                      {...register('configuration.includeDeleted')}
                      label={schema?.includeDeleted?.title}
                    />
                  </EditPermission>
                </div>
              )}
            </div>
          )}
        </SideBySide>
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || loading}
          disabled={loading}
          refresh={handleRefresh}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
