import { useMutation, useQuery } from '@apollo/client';
import { json } from '@codemirror/lang-json';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { Icon } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';

import { useHotkeys } from 'react-hotkeys-hook';
import {
  Button,
  DisabledSelect,
  Editor,
  EditPermission,
  FieldTypeSelect,
  JsonViewer,
  LacksPermissionBanner,
  MyInput,
  PromptUnsaved,
  SideBySide,
  TableWrap,
  Textarea,
  Tooltip
} from '~/components';
import { FieldType, ModelDocument, UpdateFieldDocument } from '~/generated/graphql';
import { AclProvider, useModelFields } from '~/hooks';
import { fieldTypeIconName, isStr, NO_EDIT_PERMISSION, routes } from '~/utils';
import { SyncsUsingField } from './syncs-using-field';

interface FormValues {
  label: string;
  description: string;
  example: string;
  type: FieldType;
}

export function FieldConfig() {
  const history = useHistory();
  const { fieldsetId, fieldId } = useParams<{ fieldsetId: string; fieldId: string }>();

  const { fieldsets, modelFields, loading } = useModelFields();

  const fieldset = React.useMemo(() => fieldsets?.[fieldsetId], [fieldsets, fieldsetId]);
  const field = React.useMemo(
    () => modelFields.find(f => f.id === fieldId),
    [modelFields, fieldId]
  );

  const { register, handleSubmit, reset, formState, control, watch, setValue, getValues } =
    useForm<FormValues>();
  const { errors, dirtyFields, isDirty } = formState;

  const type = watch('type');

  React.useEffect(() => {
    if (field) {
      reset({
        label: field.label || '',
        description: field.description || '',
        example: isStr(field.example) ? field.example : JSON.stringify(field.example),
        type: field.type || undefined
      });
    }
  }, [field, getValues, reset]);

  const goBack = React.useCallback(() => {
    history.push(routes.models);
  }, [history]);

  const [updateField, { loading: updateFieldLoading }] = useMutation(UpdateFieldDocument, {
    update: (cache, { data }) => {
      if (!data || !data.updateField) {
        return;
      }
      cache.writeQuery({
        query: ModelDocument,
        data: { model: { __typename: 'Model', fieldsets: data.updateField.fieldsets } }
      });
      // force syncs to refetch
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'syncs'
      });
      reset();
      goBack();
    }
  });

  const onSubmit: SubmitHandler<FormValues> = form => {
    if (!field) {
      throw new Error('Field is missing');
    }
    void updateField({
      variables: {
        update: {
          id: fieldId,
          published: !!field.published,
          label: form.label,
          description: form.description,
          example: form.example,
          type: form.type
        }
      }
    });
  };

  useHotkeys('Escape', () => goBack());

  if (!loading && !field) {
    return <EmptyPage message="Field is missing." />;
  }

  return (
    <AclProvider value={fieldset?.acl}>
      <Helmet title={`${field?.label || 'Field'} | Polytomic`} />
      <PageLayout
        topNavHeading="Edit field information"
        topNavActions={
          <>
            <Button theme="outline" iconEnd="CloseX" onClick={goBack}>
              Cancel
            </Button>
            <EditPermission>
              <Button
                theme="outline"
                onClick={handleSubmit(onSubmit)}
                loading={updateFieldLoading}
                iconEnd="Check"
              >
                Save
              </Button>
            </EditPermission>
          </>
        }
        loading={loading}
      >
        <EditPermission
          fallback={<LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper="px-3 pt-3" />}
        />
        <div className="divide-y divide-gray-300">
          <SideBySide hasSectionWrap={true} heading="Source">
            <div className="flex">
              <Tooltip
                placement="left"
                content={(field?.enrichmentFieldset ?? field?.fieldset)?.connection.name}
              >
                <div className="flex cursor-default items-center space-x-1.5 pt-1">
                  <Icon
                    match={(field?.enrichmentFieldset ?? field?.fieldset)?.connection.type.id || ''}
                  />
                  <div className="text-sm font-medium text-gray-800">{field?.sourceName}</div>
                </div>
              </Tooltip>
            </div>
          </SideBySide>
          <SideBySide hasSectionWrap={true} heading="Type">
            {field?.fieldset.connection.type.id === 'api' ? (
              <EditPermission
                fallback={
                  <DisabledSelect
                    valueLabel={
                      <>
                        <Icon
                          name={fieldTypeIconName(field?.type)}
                          className="h-5 w-5 text-gray-500"
                        />
                        <div className="ml-1.5 text-sm text-gray-800">{field?.type}</div>
                      </>
                    }
                    className="max-w-xs"
                  />
                }
              >
                <FieldTypeSelect<FormValues> name="type" control={control} />
              </EditPermission>
            ) : (
              <div className="flex">
                <Tooltip placement="left" disabled={!field?.sourceType} content={field?.sourceType}>
                  <div className="flex cursor-default items-center space-x-1.5 pt-1">
                    <Icon name={fieldTypeIconName(field?.type)} className="h-5 w-5 text-gray-500" />
                    <div className="text-sm font-medium text-gray-800">{field?.type}</div>
                  </div>
                </Tooltip>
              </div>
            )}
          </SideBySide>
          <SideBySide hasSectionWrap={true} heading="Label">
            <EditPermission>
              <MyInput
                className="max-w-xs"
                {...register('label', {
                  required: 'Label is required'
                })}
                errors={errors}
              />
            </EditPermission>
          </SideBySide>
          <SideBySide hasSectionWrap={true} heading="Description">
            <EditPermission>
              <Textarea
                className="max-w-xs"
                {...register('description')}
                placeholder="Optional"
                errors={errors}
              />
            </EditPermission>
          </SideBySide>
          <SideBySide hasSectionWrap={true} heading="Example value">
            {[FieldType.Array, FieldType.Object].includes(type) ? (
              <EditPermission
                fallback={
                  <TableWrap className="max-h-[15rem] min-h-[3.75rem] py-1.25">
                    <JsonViewer data={getValues('example')} />
                  </TableWrap>
                }
              >
                <TableWrap>
                  <Editor
                    placeholder="Enter value..."
                    language={json()}
                    defaultDoc={getValues('example')}
                    onUpdate={value => setValue('example', value)}
                    height="12.5rem"
                  />
                </TableWrap>
              </EditPermission>
            ) : (
              <EditPermission>
                <MyInput
                  className="max-w-xs"
                  {...register('example')}
                  placeholder="Optional example value"
                  errors={errors}
                />
              </EditPermission>
            )}
          </SideBySide>
          <SyncsUsingField />
          <SideBySide hasSectionWrap={true} heading="Model">
            <Link
              to={`${generatePath(routes.editModel, { fieldsetId })}?field=${field?.id}`}
              className="link flex items-center space-x-1.5 pt-1"
            >
              <Icon match={field?.fieldset.connection.type.id || ''} />
              <div className="text-sm">{field?.fieldset.name}</div>
            </Link>
          </SideBySide>
          <PromptUnsaved when={isDirty && !!dirtyFields} />
        </div>
      </PageLayout>
    </AclProvider>
  );
}
