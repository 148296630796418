import clsx from 'clsx';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { useAuth, useToggle } from '~/hooks';
import { routes } from '~/utils';
import { ErrorBoundary } from '@sentry/react';
import { Icon } from '~/components';
import { Tooltip } from '~/components/tooltip';
import { UserMenu } from '~/components/user-menu';
import { IconName } from '~/assets';
import { useConfiguration } from '~/hooks/configuration';
import { useCallback } from 'react';
import { QuickSearch } from '~/components/v3';

export type RouteParams = {
  id: string;
};

export type NavLinkType = {
  title: string;
  to: string;
  icon: IconName;
  featureFlag?: string;
};

export type SideNavOuterProps = null;

export const menuPaddingStyles = `py-3 px-3.5`;
export const menuItemStyles = `rounded hover:bg-gray-700 hover:bg-opacity-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300`;
export const menuItemActiveStyles = `text-neon bg-gray-700`;

export const SideNavOuter = () => {
  const { user } = useAuth();
  const atSync = useRouteMatch<RouteParams>(routes.sync);
  const atBulkSync = useRouteMatch<RouteParams>(routes.bulkSync);
  const syncId = atSync ? atSync.params.id : null;
  const bulkSyncId = atBulkSync ? atBulkSync.params.id : null;
  const { state: configuration } = useConfiguration();
  const [open, toggleOpen] = useToggle();

  const navLinks: NavLinkType[] = [
    {
      title: 'Dashboard',
      to: routes.dashboard,
      icon: 'Pulse'
    },
    {
      title: 'Models',
      to: routes.models,
      icon: 'Model'
    },
    {
      title: 'Model syncs',
      to: syncId ? `${routes.syncs}?id=${syncId}` : routes.syncs,
      icon: 'Syncs'
    },
    {
      title: 'Bulk syncs',
      to: bulkSyncId ? `${routes.bulkSyncsRoot}?id=${bulkSyncId}` : routes.bulkSyncsRoot,
      icon: 'Truck'
    },
    {
      title: 'Connections',
      to: routes.connections,
      icon: 'Generic'
    },
    {
      title: 'Query runner',
      to: routes.queryRunner,
      icon: 'Terminal'
    },
    {
      title: 'Explore',
      to: routes.explore,
      icon: 'Search',
      featureFlag: 'explore'
    },
    {
      title: 'Team',
      to: routes.team,
      icon: 'Team'
    },
    {
      title: 'Settings',
      to: routes.user,
      icon: 'Gear'
    }
  ];
  if (!user) {
    return null;
  }

  const hasFeatureFlagEnabled = useCallback(
    (featureFlag: string) => {
      return !featureFlag ? true : user.organization?.features?.includes(featureFlag);
    },
    [user]
  );

  return (
    <nav
      className="relative z-90 flex h-full min-w-[4rem] flex-col bg-gray-800 text-white xl:min-w-[15rem]"
      role="navigation"
    >
      <ul className="grow space-y-0.5 overflow-y-auto px-2 pt-2 pb-0.5">
        <li className="mb-3 flex items-center justify-center space-x-2 py-3 px-2.5 xl:justify-start">
          <Icon name="PolytomicTriangle" className="h-7 w-7 text-neon" />
          <Icon
            name="PolytomicWordmark"
            className="hidden h-3.5 w-[7.375rem] text-white xl:block"
          />
        </li>
        <li
          className="group hidden cursor-pointer items-center justify-center space-x-2 p-2 xl:block"
          onClick={toggleOpen}
        >
          <div className="flex w-full items-center justify-between rounded-full border border-gray-600 px-3 py-2 text-gray-400 group-hover:bg-gray-600">
            <div className="flex items-center gap-2">
              <Icon name="Search" />
              <span>Search...</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="flex h-6 w-6 items-center justify-center rounded-sm border border-gray-600 group-hover:bg-gray-400 group-hover:text-gray-600">
                ⌘
              </div>
              <div className="flex h-6 w-6 items-center justify-center rounded-sm border border-gray-600 group-hover:bg-gray-400 group-hover:text-gray-600">
                K
              </div>
            </div>
          </div>
        </li>
        <li className="xl:hidden" onClick={toggleOpen}>
          <Tooltip placement="right" className="block xl:hidden" content="Search">
            <div
              className={clsx(
                'flex cursor-pointer items-center justify-center space-x-2.5 xl:justify-start',
                menuPaddingStyles,
                menuItemStyles
              )}
            >
              <Icon name="Search" />
            </div>
          </Tooltip>
        </li>
        {navLinks.map(({ title, to, icon, featureFlag }) =>
          !hasFeatureFlagEnabled(featureFlag) ? null : (
            <li key={title}>
              <Tooltip placement="right" className="block xl:hidden" content={title}>
                <NavLink
                  to={to}
                  className={clsx(
                    'flex items-center justify-center space-x-2.5 xl:justify-start',
                    menuPaddingStyles,
                    menuItemStyles
                  )}
                  activeClassName={menuItemActiveStyles}
                >
                  <Icon name={icon} className="h-5 w-5" />
                  <span className="hidden text-sm font-semibold text-white xl:block">{title}</span>
                </NavLink>
              </Tooltip>
            </li>
          )
        )}
        <li>
          <Tooltip placement="right" className="block xl:hidden" content="Documentation">
            <a
              className={clsx(
                ' flex items-center justify-center space-x-2.5 xl:justify-start',
                menuPaddingStyles,
                menuItemStyles
              )}
              href="https://docs.polytomic.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="Book" className="h-5 w-5" />
              <span className="hidden text-sm font-semibold text-white xl:block">
                Documentation
              </span>
            </a>
          </Tooltip>
        </li>
        <ErrorBoundary>
          {configuration.deployment_links?.length > 0 && (
            <div className="py-8">
              {configuration.deployment_links.map(link => (
                <li key={link.url}>
                  <Tooltip placement="right" className="block xl:hidden" content={link.name}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(
                        'flex items-center justify-center space-x-2.5 xl:justify-start',
                        menuPaddingStyles,
                        menuItemStyles
                      )}
                    >
                      <Icon name="Book" className="h-5 w-5" />
                      <span className="hidden text-sm font-semibold text-white xl:block">
                        {link.name}
                      </span>
                    </a>
                  </Tooltip>
                </li>
              ))}
            </div>
          )}
        </ErrorBoundary>
      </ul>
      <ErrorBoundary>
        <UserMenu user={user} />
      </ErrorBoundary>
      <QuickSearch open={open} toggleOpen={toggleOpen} />
    </nav>
  );
};

export default SideNavOuter;
