import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DisabledSelect, EditPermission, MyCombobox, Section, SideBySide } from '~/components';
import { WarehouseConfiguration } from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  filterToIds,
  findName,
  getSchemaAsList,
  objToSelectables
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable, ModelQueryEditor, TrackingColumns } from '../components';

const modeConfiguration: ModeConfiguration<WarehouseConfiguration> = {
  modes: [
    { name: 'table', label: 'Table select' },
    { name: 'query', label: 'SQL query' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      schema: mode === 'table' ? conf.schema : '',
      table: mode === 'table' ? conf.table : '',
      query: mode === 'query' ? conf.query : '',
      trackingColumns: conf.trackingColumns != null ? [...conf.trackingColumns] : []
    }
  }),
  reset: { configuration: { table: '', query: '', view: '', trackingColumns: [] } }
};

const queryUpdateObj = (conf: WarehouseConfiguration | undefined) => ({
  configuration: {
    schema: '',
    table: '',
    query: conf?.query || '',
    trackingColumns: conf?.trackingColumns != null ? filterToIds(conf.trackingColumns) : []
  }
});

export function SeparateSqlConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const { modes, mode, handleMode } = useModeSwitcher<WarehouseConfiguration>(modeConfiguration);

  const schema = useWatch({ control, name: 'configuration.schema' });
  const table = useWatch({ control, name: 'configuration.table' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );
  const defaultDoc = (fieldset?.configuration as WarehouseConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  const handleSchemaUpdate = React.useCallback(() => {
    applyUpdate(
      {
        schema: getValues('configuration.schema'),
        table: '',
        query: '',
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleTableUpdate = React.useCallback(() => {
    applyUpdate(
      {
        schema: getValues('configuration.schema'),
        table: getValues('configuration.table'),
        query: '',
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate(
      {
        ...getValues('configuration'),
        trackingColumns: filterToIds(getValues('configuration.trackingColumns'))
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          <EditPermission
            fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
          >
            <MyCombobox
              className="max-w-xs"
              value={{ label: modes[mode], value: mode }}
              options={objToSelectables(modes, true)}
              onChange={handleMode}
              isDisabled={loading}
            />
          </EditPermission>
          {mode === 'table' && (
            <div className="mt-2 w-full max-w-xs animate-fadeIn space-y-2">
              <EnumPicker
                item={findName(list, 'configuration.schema')}
                onChange={handleSchemaUpdate}
              />
              {schema && (
                <EnumPicker
                  item={findName(list, 'configuration.table')}
                  onChange={handleTableUpdate}
                />
              )}
            </div>
          )}
        </SideBySide>
        {mode === 'query' && (
          <ModelQueryEditor
            key={fieldset.connection.id}
            heading="SQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || loading}
          disabled={loading}
          refresh={table ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
      <TrackingColumns />
    </>
  );
}
