import { useQuery } from '@apollo/client';
import { generatePath, useHistory } from 'react-router-dom';
import { PeekSyncsDocument } from '~/generated/graphql';
import { defaultSearchConfig, MiniSearchConfig, routes } from '~/utils';
import { Icon, Truncator } from '~/components';
import { SearchResult } from '..';
import { Fragment, useMemo } from 'react';
import { CommandItem } from '../Command';
import MiniSearch from 'minisearch';

const DELIMETER = '~.~';

type SyncResult = {
  id: string;
  name: string;
  sourceConnectionTypes: string;
  targetConnectionType: string;
};

export function useSyncsSearch(): SearchResult<SyncResult> {
  const history = useHistory();
  const { data } = useQuery(PeekSyncsDocument);

  const config: MiniSearchConfig<SyncResult> = {
    ...defaultSearchConfig,
    fields: ['id', 'name', 'sourceConnectionTypes', 'targetConnectionType'],
    storeFields: ['id', 'name', 'sourceConnectionTypes', 'targetConnectionType']
  };

  const index = useMemo(() => {
    const mini = new MiniSearch(config);
    mini.addAll(
      (data?.peekSyncs ?? []).map<SyncResult>(sync => ({
        id: sync.id,
        name: sync.name,
        sourceConnectionTypes: sync.SourceConnections?.map(c => c.ConnectionType).join(DELIMETER),
        targetConnectionType: sync.TargetConnectionType
      }))
    );
    return mini;
  }, [data]);

  return {
    title: 'Model syncs',
    search: search => index.search(search) as unknown as SyncResult[],
    render: (sync, handleSelect) => (
      <CommandItem
        key={sync.id}
        value={sync.id}
        onSelect={handleSelect(() => history.push(generatePath(routes.sync, { id: sync.id })))}
        className="flex items-center gap-2"
      >
        <div className="flex items-center gap-1">
          {sync.sourceConnectionTypes.split(DELIMETER).map((connection, i) => (
            <Fragment key={connection}>
              {i > 0 && <Icon name="PlusSmall" className="-mx-1.5" />}
              <Icon match={connection} />
            </Fragment>
          ))}
          <Icon name="Syncs" />
          {sync.targetConnectionType && (
            <Icon match={sync.targetConnectionType} className="mr-1.5" />
          )}
        </div>
        <Truncator content={sync.name}>
          <span className="truncate">{sync.name}</span>
        </Truncator>
      </CommandItem>
    )
  };
}
