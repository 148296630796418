import { defaultRangeExtractor, useVirtualizer, type Range } from '@tanstack/react-virtual';
import { isString, keyBy } from 'lodash';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Command, CommandEmpty, CommandInput, CommandList } from '../Command';
import { Dialog } from '../Dialog';
import { useAccountSearch } from './useAccountSearch';
import { useActionSearch } from './useActionSearch';
import { useBulkSyncsSearch } from './useBulkSyncSearch';
import { useConnectionsSearch } from './useConnectionSearch';
import { useFieldSearch } from './useFieldSearch';
import { useModelSearch } from './useModelSearch';
import { useNavigationSearch } from './useNavigationSearch';
import { useSyncsSearch } from './useSyncSearch';
import { isMacOs } from 'react-device-detect';
import { useTableSearch } from './useTableSearch';

export const match = (paths: string[], search: string) => {
  return !search || paths.some(path => path.toLowerCase().includes(search.toLowerCase()));
};

export interface SearchResult<T> {
  title: string;
  search: (search: string) => T[];
  render: (result: T, handleSelect: any) => ReactElement;
}

interface QuickSearchProps {
  open: boolean;
  toggleOpen: () => void;
}

export function QuickSearch({ open, toggleOpen }: QuickSearchProps) {
  const [search, setSearch] = useState<string>('');

  const accountSearch = useAccountSearch();
  const actionSearch = useActionSearch();
  const bulkSearch = useBulkSyncsSearch();
  const connectionSearch = useConnectionsSearch();
  const fieldSearch = useFieldSearch();
  const modelSearch = useModelSearch();
  const navigationSearch = useNavigationSearch();
  const syncSearch = useSyncsSearch();
  const tableSearch = useTableSearch();

  const searches = [
    actionSearch,
    navigationSearch,
    accountSearch,
    modelSearch,
    syncSearch,
    bulkSearch,
    connectionSearch,
    tableSearch,
    fieldSearch
  ];
  const searchesByTitle = keyBy(searches, s => s.title);
  const results = useMemo(
    () =>
      searches.reduce((acc, s) => {
        const items = s.search(search).map(r => ({ ...r, render: s.title }));
        return !!items.length ? [...acc, s.title, ...items] : acc;
      }, []),
    [search]
  );

  const scrollRef = useRef<HTMLDivElement>();

  const handleSearchChange = (search: string) => {
    setSearch(search);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const handleSelect = (fn?: () => void) => () => {
    toggleOpen();
    setSearch('');
    setTimeout(fn);
  };

  useHotkeys(
    `${isMacOs ? 'meta' : 'ctrl'}+k`,
    e => {
      e.preventDefault();
      toggleOpen();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
      enableOnContentEditable: true
    }
  );

  const activeStickyIndexRef = useRef(0);

  const stickyIndexes = useMemo(
    () => results.reduce((acc, r, i) => (isString(r) ? [...acc, i] : acc), []),
    [results]
  );

  const isSticky = (index: number) => stickyIndexes.includes(index);

  const isActiveSticky = (index: number) => activeStickyIndexRef.current === index;

  const rowVirtualizer = useVirtualizer({
    count: results.length,
    estimateSize: () => 36,
    getScrollElement: () => scrollRef.current,
    rangeExtractor: (range: Range) => {
      activeStickyIndexRef.current =
        [...stickyIndexes].reverse().find(index => range.startIndex >= index) ?? 0;

      const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);

      return [...next].sort((a, b) => a - b);
    },
    initialOffset: 0,
    overscan: 20
  });

  useEffect(() => {
    rowVirtualizer.measure();
  }, [open]);

  return (
    <Dialog show={open} onDismiss={handleSelect()} classNames={{ body: 'p-0' }} size="lg">
      <Command className="h-full rounded-lg shadow-md md:min-w-[450px]" shouldFilter={false}>
        <CommandInput
          placeholder="Type a command or search models, syncs, fields and more"
          value={search}
          onValueChange={handleSearchChange}
        />
        <CommandList ref={scrollRef} className="px-2 pb-2">
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const result = results[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  style={{
                    ...(isSticky(virtualRow.index)
                      ? {
                          background: '#fff',
                          zIndex: 1
                        }
                      : {}),
                    ...(isActiveSticky(virtualRow.index)
                      ? {
                          position: 'sticky'
                        }
                      : {
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`
                        }),
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualRow.size}px`
                  }}
                  tabIndex={0}
                >
                  {isString(result) ? (
                    <div
                      key={`heading-${result}`}
                      className="px-2 py-3 text-xs font-medium text-gray-500"
                    >
                      {result}
                    </div>
                  ) : (
                    searchesByTitle[result.render].render(result, handleSelect)
                  )}
                </div>
              );
            })}
          </div>
          {!results.length && <CommandEmpty>No results</CommandEmpty>}
        </CommandList>
      </Command>
    </Dialog>
  );
}
