import { uniqBy } from 'lodash';
import { MutableRefObject, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { ModelFieldFragment, OverrideFragment } from '~/generated/graphql';
import {
  hasItems,
  hasMissingSource,
  isModelField,
  isModelMapping,
  LocalConnection,
  SyncConfigFormValues,
  toSelectables
} from '~/utils';
import { FieldDetail } from '~/components/field-detail';
import {
  FilterValueElements,
  MyCombobox,
  MyInput,
  ParamButton
} from '~/components/form-components';
import { LinkableIcon } from '~/components/linkable-icon';
import PageLayout from '~/components/v2/layout/PageLayout';
import FieldMappingBottomNav from '~/components/v2/experimental/FieldMappingBottomNav';
import FieldMappingButton from '~/components/v2/experimental/FieldMappingButton';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { ModelField, useFilterFunctions, useModelFields } from '~/hooks';
import { RowControl } from '~/components/v3/RowControl';

export type StageMappingsOverridesProps = {
  showFieldDetails?: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  showBottomNav: boolean;
  setShowBottomNav: React.Dispatch<React.SetStateAction<boolean>>;
  bottomNavConfig: { config: FIELD_MAPPING_TYPE; mappingIndex: number };
  setBottomNavConfig: React.Dispatch<
    React.SetStateAction<{ config: FIELD_MAPPING_TYPE; mappingIndex: number }>
  >;
  buttonRefs?: MutableRefObject<Record<string | number, HTMLButtonElement | null>>;
  // F(x)
  focusOnMappingButton?: (id?: string) => void;
};

const StageMappingsOverrides = ({
  showFieldDetails,
  setIsDirty,
  showBottomNav,
  setShowBottomNav,
  bottomNavConfig,
  setBottomNavConfig,
  buttonRefs,
  focusOnMappingButton
}: StageMappingsOverridesProps) => {
  // hooks
  const { register, control, getValues } = useFormContext<SyncConfigFormValues>();
  const targetConnection = getValues('targetConnection');
  const overrides = useWatch({ control, name: 'overrides' });
  const mappings = useWatch({ control, name: 'mappings' });
  const { fields, append, remove, replace } = useFieldArray({ control, name: 'overrides' });
  const { modelFields } = useModelFields();
  const { getFilterFunctions, getFilterFunction } = useFilterFunctions();
  // state
  const [autoSelectedModelId, setAutoSelectedModelId] = useState<string>('');
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
  // memo
  const overrideOptions = useMemo(() => {
    if (!mappings) return [];
    const opts = mappings.reduce((acc: ModelFieldFragment[], mapping) => {
      if (isModelMapping(mapping) && mapping.model) {
        const field = modelFields.find(f => f.id === mapping.model.id);
        if (field) {
          acc.push(field);
        }
      }
      return acc;
    }, []);
    return uniqBy(opts, 'id');
  }, [mappings]);
  // vars
  const hideOverrides =
    (!overrides || overrides.length === 0) && (!mappings || !mappings.some(isModelMapping));
  // f(x)
  const handleAdd = () => {
    setIsDirty(true);
    append({
      field: null,
      function: null,
      value: null,
      overrideValue: ''
    });
    focusOnMappingButton?.(`${bottomNavConfig.mappingIndex + 1}${FIELD_MAPPING_TYPE.OVERRIDE}`);
  };
  const handleDelete = (index: number) => {
    setIsDirty(true);
    remove(index);
  };

  const currentFieldId = useMemo(() => {
    if (fields[bottomNavConfig.mappingIndex]) {
      return fields[bottomNavConfig.mappingIndex]?.field?.id || undefined;
    } else {
      return undefined;
    }
  }, [fields, bottomNavConfig.mappingIndex]);

  const getFieldDetails = (override: OverrideFragment) => {
    const field = modelFields?.find(f => f.id === override.field?.id) as ModelField;
    const fieldset = field?.fieldset;
    return {
      logoId: fieldset?.connection?.type?.id,
      enrichmentLogoId: field?.enrichmentFieldset?.connection?.type?.id,
      modelId: fieldset?.id,
      modelName: fieldset?.name,
      columnName: field?.sourceName,
      type: field?.type
    };
  };

  return hideOverrides ? null : (
    <>
      {bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE && (
        <PageLayout
          bottomNavShow={showBottomNav}
          bottomNavContent={
            bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE && (
              <FieldMappingBottomNav
                autoSelectedModelId={autoSelectedModelId}
                setAutoSelectedModelId={setAutoSelectedModelId}
                mappingIndex={bottomNavConfig.mappingIndex}
                targetConnection={targetConnection as LocalConnection}
                setShowBottomNav={setShowBottomNav}
                fieldType={FIELD_MAPPING_TYPE.OVERRIDE}
                title={'Select override field'}
                fields={overrideOptions}
                selectedFieldIds={selectedFieldIds}
                setSelectedFieldIds={setSelectedFieldIds}
                disabledFieldIds={[]}
                multiSelect={false}
                currentFieldId={currentFieldId}
                handleAddFields={(
                  fieldType: FIELD_MAPPING_TYPE,
                  mappingIndex: number,
                  fieldIds: string[]
                ) => {
                  const fieldMappingAtIndex = fields[mappingIndex];
                  replace([
                    ...fields.slice(0, mappingIndex),
                    ...[
                      {
                        ...fieldMappingAtIndex,
                        field: overrideOptions.find(f => f.id === fieldIds[0]),
                        function: null,
                        value: null
                      }
                    ],
                    // Fields after the selected index
                    ...fields.slice(mappingIndex + 1, fields.length)
                  ]);
                  setIsDirty(true);
                  focusOnMappingButton?.();
                }}
                linkButton={undefined}
              />
            )
          }
        />
      )}
      <div className="col-span-full mt-6 border-t border-gray-300 px-6 pt-6">
        <div className="mb-2 flex items-center space-x-1">
          <label className="block text-sm font-semibold">Overrides</label>
          <LinkableIcon href="https://docs.polytomic.com/docs/mapping-overrides" />
        </div>
        {hasItems(fields) ? (
          <section className="space-y-3">
            {fields.map((field, index) => {
              const override = overrides && overrides.length >= index ? overrides[index] : null;
              const isInvalid =
                override && isModelField(override.field) && hasMissingSource(override, mappings);
              return (
                <div
                  key={field.id}
                  className="grid w-full grid-cols-[3fr,2.4fr,3fr,3rem] space-x-2 space-y-1"
                >
                  <label className="thead-text col-span-full -mb-0.75 block text-gray-500">
                    If
                  </label>
                  <div className="col-start-1 col-end-2">
                    <div>
                      <FieldMappingButton
                        buttonRef={el => {
                          if (buttonRefs) {
                            buttonRefs.current[`${index}${FIELD_MAPPING_TYPE.OVERRIDE}`] = el;
                          }
                        }}
                        fieldType={FIELD_MAPPING_TYPE.OVERRIDE}
                        mappingIndex={index}
                        placeholder={'Model field...'}
                        disabled={false}
                        sanitize={false}
                        iconId={
                          (fields[index].field as ModelField)?.enrichmentFieldset?.connection?.type
                            ?.id ??
                          (fields[index].field as ModelField)?.fieldset?.connection?.type?.id
                        }
                        label={(fields[index].field as ModelFieldFragment)?.label}
                        selected={
                          bottomNavConfig.mappingIndex === index &&
                          bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE &&
                          showBottomNav
                        }
                        onClick={() => {
                          setBottomNavConfig({
                            config: FIELD_MAPPING_TYPE.OVERRIDE,
                            mappingIndex: index
                          });
                          setAutoSelectedModelId('');
                          setShowBottomNav(true);
                        }}
                      />
                    </div>
                    {isInvalid && (
                      <p className="mt-0.5 text-xs font-medium text-red-500">
                        Invalid override: model field removed
                      </p>
                    )}
                    {showFieldDetails && override?.field && (
                      <FieldDetail
                        defaultStyles="self-stretch p-2 pl-3"
                        {...getFieldDetails(override)}
                      />
                    )}
                  </div>
                  <div className="col-start-2 col-end-3 pt-1">
                    <MyCombobox
                      variant="flat"
                      options={
                        override?.field
                          ? toSelectables(getFilterFunctions(override?.field?.type) ?? [])
                          : []
                      }
                      // @ts-expect-error this value is a lie but it's our lie
                      value={
                        fields[index].function
                          ? {
                              label:
                                getFilterFunction(override?.field?.type, fields[index]?.function)
                                  ?.label ?? fields[index].function,
                              value: fields[index].function.toString()
                            }
                          : null
                      }
                      placeholder="Compare..."
                      onChange={option => {
                        setIsDirty(true);
                        replace([
                          ...fields.slice(0, index),
                          ...[
                            {
                              ...fields[index],
                              function: option?.value
                            }
                          ],
                          // Fields after the selected index
                          ...fields.slice(index + 1, fields.length)
                        ]);
                      }}
                      isDisabled={!override?.field}
                    />
                  </div>
                  <div className="col-start-3 col-end-4 px-1 pt-1">
                    <FilterValueElements
                      variant="flat"
                      obj={field}
                      value={field.value}
                      fieldType={field.field?.type}
                      filterFunction={getFilterFunction(field?.field?.type, field.function)}
                      handleValue={value => {
                        setIsDirty(true);
                        replace(
                          fields.map((field, i) => (i === index ? { ...field, value } : field))
                        );
                      }}
                    />
                  </div>

                  <label className="col-span-full -mt-px mb-0.75 block text-xs text-gray-500">
                    replace with
                  </label>
                  <div className="col-start-1 col-end-4 pr-1">
                    <MyInput
                      variant="flat"
                      {...register(`overrides.${index}.overrideValue`)}
                      placeholder="Override value..."
                      disabled={!override?.field || !override.function}
                    />
                  </div>
                  <div className="pt-2">
                    <RowControl
                      index={index}
                      total={overrides?.length}
                      canAdd={
                        index === overrides.length - 1 &&
                        hasItems(overrideOptions) &&
                        !!overrides[overrides.length - 1].field
                      }
                      onAdd={handleAdd}
                      onDelete={() => handleDelete(index)}
                    />
                  </div>
                </div>
              );
            })}
          </section>
        ) : (
          <ParamButton action="add" onClick={handleAdd} />
        )}
      </div>
    </>
  );
};

export default StageMappingsOverrides;
