import { v4 as uuid } from 'uuid';

import {
  CompletionValue,
  FieldType,
  FilterFragment,
  FilterFunction,
  OverrideFragment,
  SupportedFilterFunction
} from '../../generated/graphql';
import { hasItems, isTargetField, Selectable } from '../../utils';
import { MyCombobox } from '..';
import { BetweenNumbersInput, MultiInput, MyInput } from '.';
import { DatePicker } from '../v3';

interface Props {
  fieldType: FieldType;
  filterFunction: SupportedFilterFunction;
  obj?: FilterFragment | OverrideFragment | undefined | null;
  value: unknown;
  handleValue: (value: CompletionValue | string | string[]) => void;
  variant?: 'outlined' | 'flat';
}

export function FilterValueElements({
  obj,
  value,
  fieldType,
  filterFunction,
  handleValue,
  variant = 'outlined'
}: Props) {
  if (!filterFunction || !fieldType || !filterFunction.requiresValue) {
    return null;
  }
  if (fieldType === FieldType.Datetime) {
    if (
      [
        FilterFunction.RelativeOnOrAfter,
        FilterFunction.RelativeOnOrBefore,
        FilterFunction.InTheLast
      ].includes(filterFunction.id)
    ) {
      return (
        <MyCombobox
          variant={variant}
          className="col-start-3 col-end-4"
          options={filterFunction.values as Selectable[]}
          value={(filterFunction.values.find(v => v.value === value) as Selectable) || null}
          placeholder="Value..."
          onChange={newValue => {
            if (!newValue) {
              return;
            }
            handleValue(newValue.value);
          }}
        />
      );
    }
    return <DatePicker variant={variant} date={value as string} onSelect={v => handleValue(v)} />;
  }
  if (obj?.field && isTargetField(obj?.field) && hasItems(filterFunction.values)) {
    return (
      <MyCombobox
        variant={variant}
        className="col-start-3 col-end-4"
        options={obj.field && isTargetField(obj.field) ? (obj.field.values as Selectable[]) : []}
        value={obj.field ? (obj.value as Selectable) : null}
        placeholder="Value..."
        onChange={newValue => {
          if (!newValue) {
            return;
          }
          handleValue(newValue);
        }}
      />
    );
  }
  if (fieldType === FieldType.Number) {
    if (filterFunction.id === FilterFunction.Between) {
      return (
        <BetweenNumbersInput
          variant={variant}
          values={value as string[]}
          onUpdate={values => handleValue(values)}
        />
      );
    }
    return (
      <MyInput
        variant={variant}
        name={uuid()}
        defaultValue={(value as string) ?? undefined}
        placeholder="Number..."
        onBlur={e => handleValue(e.target.value)}
      />
    );
  }
  if (filterFunction?.multiValue) {
    return <MultiInput variant={variant} values={value} onUpdate={values => handleValue(values)} />;
  }
  return (
    <MyInput
      variant={variant}
      name={uuid()}
      defaultValue={(value as string) ?? undefined}
      placeholder="Value..."
      onBlur={e => handleValue(e.target.value)}
    />
  );
}
