import { FieldErrors, get } from 'react-hook-form';
import { IconName } from '~/assets';

import { AuthLocationType, Selectable } from './custom-types.util';
import { isStr, truthyBoolean } from './predicates.util';

import { lowerCase, capitalize, isObject, isArray, isString } from 'lodash';
import { isValidElement } from 'react';

export const IS_MAC = navigator.userAgent.includes('Mac');

export function plural(str: string, condition: boolean, suffix = 's') {
  if (condition) {
    return `${str}${suffix}`;
  }
  return str;
}

export function capsFirst(str: string) {
  return capitalize(lowerCase(str));
}

export function getLetters(count: number) {
  const remainder = count % 26;

  const cycle = Math.floor(count / 26);
  const cycleRemainder = cycle % 26;

  const columnIdx = cycleRemainder === 0 ? cycle : cycleRemainder;
  const extraColumns = Math.ceil(cycle / 26);

  const str: number[] = [];

  for (let i = 0; i < extraColumns; i++) {
    str.push(columnIdx + 64);
  }

  return String.fromCharCode(...str, remainder + 65);
}

export function fieldTypeIconName(type: string | undefined | null): IconName {
  if (!type) {
    return 'TypeUnknown';
  }
  switch (type) {
    case 'boolean':
      return 'TypeBoolean';
    case 'string':
      return 'TypeString';
    case 'datetime':
      return 'TypeDate';
    case 'number':
      return 'TypeNumber';
    case 'object':
      return 'TypeObject';
    case 'array':
      return 'TypeArray';
    default:
      return 'TypeUnknown';
  }
}

function getId<Type extends { id: string }>(obj: Type | null | undefined) {
  if (!obj || typeof obj !== 'object' || !('id' in obj)) {
    return '';
  }
  return obj.id;
}

export function filterToIds<Type extends { id: string }>(arr: Type[]): string[] {
  if (!arr || arr.length === 0) {
    return [];
  }
  return arr.filter(truthyBoolean).map(getId);
}

export function humanizeText(str: string) {
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    const char = str[i];
    // first letter a-z
    if (i === 0 && code >= 97 && code <= 122) {
      result += char.toUpperCase();
    } else if (
      // a-z
      (code >= 97 && code <= 122) ||
      // 0-9
      (code >= 48 && code <= 57) ||
      // A-Z
      (code >= 65 && code <= 90) ||
      // {space}
      code === 32 ||
      // :
      code === 58
    ) {
      result += char;
      // - _
    } else if (code === 45 || code === 95) {
      result += ' ';
      // {period} {middle dot}
    } else if (code === 46 || code === 183) {
      result += ': ';
    }
  }

  return result;
}

export function getIndexOfId<Type extends { id: string }>(id: string, arr: Type[]) {
  if (!arr || arr.length === 0) {
    return -1;
  }
  return arr.findIndex(item => item.id === id);
}

export function flatToSelectable<T>(item: T): Selectable {
  const result = isStr(item) ? item : String(item);
  return {
    label: result,
    value: result
  };
}

export function objToSelectables<T>(arr: T, flipOrder = false): Selectable[] {
  if (flipOrder) {
    //@ts-ignore
    return Object.entries(arr).map(([key, value]) => ({
      label: isStr(value) ? value : String(value),
      value: key
    }));
  }
  //@ts-ignore
  return Object.entries(arr).map(([key, value]) => ({
    label: key,
    value: isStr(value) ? value : String(value)
  }));
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | string {
  const target = Object.entries(myEnum).find(([_, value]) => value === enumValue);
  if (target) {
    return target[0];
  }
  return enumValue;
}

// getValueAsFormattedString accepts an unknown value and returns a formatted
// string if possible. If the value is a JSON string, it will be parsed and
// re-stringified to format it.
export function getValueAsFormattedString(value: unknown) {
  if (isValidElement(value)) {
    return value;
  }
  if (isObject(value) || isArray(value)) {
    return JSON.stringify(value, null, 2);
  }
  if (isString(value) && isJsonString(value)) {
    return JSON.stringify(JSON.parse(value), null, 2);
  }
  return String(value);
}

export function isRequiredMsg(field: string | undefined | null) {
  return `${field || 'Field'} is required`;
}

export function joinPath(path: string | undefined | null, key: string) {
  return path ? `${path}.${key}` : key;
}

export function hasError(errors: FieldErrors | undefined, name: string | undefined) {
  if (!name || !errors) {
    return false;
  }
  return !!get(errors, name);
}

export function setTempLocal<ValueType>(key: string, value: ValueType) {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch {
    return;
  }
}

export function getTempLocal<ValueType>(key: string) {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as ValueType) : undefined;
  } catch {
    return;
  }
}

export function removeTempLocal(key: string) {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.removeItem(key);
}

export function handleContinueUrl() {
  const result = JSON.parse(window.localStorage.getItem('continueUrl') || '{}') as AuthLocationType;
  // we stored the value from local storage in the var,
  // so we don't need it anymore and this might guard
  // against accidental link zooming when switching orgs
  window.localStorage.removeItem('continueUrl');
  if (!result?.referrer) {
    return;
  }
  return result;
}

export const getScrollParent = (node, axis = 'y') => {
  let el = node;
  if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
    return null;
  }

  if (el instanceof ShadowRoot) {
    el = el.host;
  }
  const style = window.getComputedStyle(el);
  const overflow = axis === 'y' ? style.overflowY : style.overflowX;
  const scrollSize = axis === 'y' ? el.scrollHeight : el.scrollWidth;
  const clientSize = axis === 'y' ? el.clientHeight : el.clientWidth;
  const isScrolled = scrollSize > clientSize;

  if (isScrolled && !overflow.includes('visible') && !overflow.includes('hidden')) {
    return el;
  }

  return getScrollParent(el.parentNode, axis) || document.body;
};

export async function scrollElementIntoViewWithDrawer(element, paddingBottom = 100) {
  if (!element) {
    return;
  }

  const parent = getScrollParent(element);
  const dh = parseInt(localStorage.getItem('bottomNavHeight'), 10) || 224;

  const dt = window.innerHeight - dh;
  const bottom = element.getBoundingClientRect().bottom;

  if (bottom + paddingBottom > dt) {
    parent.scrollTop += bottom - dt + paddingBottom;
  }
}

export function isJsonString(str: string) {
  let value;
  try {
    value = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return typeof value === 'object' && value !== null;
}

export function oxford(arr) {
  const l = arr.length;
  if (!l) return '';
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` and `);
  arr = arr.slice();
  arr[l - 1] = `and ${arr[l - 1]}`;
  return arr.join(', ');
}
