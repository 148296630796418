import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Section, SideBySide } from '~/components';
import { SchemaForm } from '~/components/common/SchemaForm';
import { ZoomInfoSource } from '~/generated/graphql';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues, findName, getSchemaAsList } from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { EnumPicker, FieldsTable } from '../components';

export function ZoomInfoFieldsetConfig() {
  const { fieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();

  const source = useWatch({ control, name: 'configuration.zoomSource' });
  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const onChange = React.useCallback(
    (config: Record<string, unknown>) => {
      applyUpdate(config, { refresh: true });
    },
    [applyUpdate]
  );
  const handleSourceUpdate = React.useCallback(() => {
    applyUpdate({ zoomSource: getValues('configuration.zoomSource') }, { refresh: true });
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset?.configuration && fieldset.configurationSchema && (
            <div className="w-full max-w-xs animate-fadeIn space-y-3">
              {source !== ZoomInfoSource.IntentData && (
                <EnumPicker
                  item={findName(list, 'configuration.zoomSource')}
                  onChange={handleSourceUpdate}
                />
              )}
              {source === ZoomInfoSource.IntentData && (
                <>
                  <SchemaForm
                    schema={fieldset.configurationSchema}
                    values={getValues('configuration')}
                    onChange={onChange}
                  />
                </>
              )}
            </div>
          )}
        </SideBySide>
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || loading}
          disabled={loading}
          refresh={source ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}
