import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button, EditPermission, Search, SideBySide, TableTopper, TableWrap } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { ModelFieldFragment } from '~/generated/graphql';
import { useFieldsetState } from '~/hooks';
import { FieldsetFormValues } from '~/utils';
import { AddField } from '../add-field';
import { FieldsPicker } from '../fields-picker';

interface FieldsTableProps {
  heading?: string;
  loading: boolean;
  disabled?: boolean;
  refresh?: () => void;
  fields: ModelFieldFragment[];
  hasWriteinFields?: boolean;
  userTypeSelection?: boolean;
}

export function FieldsTable({
  fields,
  hasWriteinFields,
  userTypeSelection,
  heading,
  disabled,
  refresh,
  loading
}: FieldsTableProps) {
  const { fieldset, setFieldset } = useFieldsetState();
  const methods = useFormContext<FieldsetFormValues>();
  const ref = useRef<HTMLDivElement>();

  const [search, setSearch] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>();
  useHotkeys(['/'], e => {
    if (searchRef.current) {
      searchRef.current.focus();
      e.preventDefault();
    }
  });

  const queryParameters = new URLSearchParams(location.search);
  const field = queryParameters.get('field');

  useEffect(() => {
    if (!loading && field && ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      });
    }
  }, [field, loading, ref.current]);

  const handleFieldsChange = (fields: ModelFieldFragment[]) => {
    setFieldset({ ...fieldset, fields });
    methods.setValue('fields', fields, { shouldDirty: true });
  };

  if (loading && isEmpty(fields)) {
    return (
      <SideBySide maxWidth="max-w-xs">
        <div className="flex h-16 items-center justify-center">
          <LoadingDots />
        </div>
      </SideBySide>
    );
  }
  if (!loading && isEmpty(fields)) {
    return null;
  }

  return (
    <div className="min-w-[calc(100%+1.5rem)] animate-fadeIn scroll-my-4 pr-6	" ref={ref}>
      <TableWrap className="min-w-full">
        <TableTopper className="h-16 justify-between space-x-4 bg-white px-4">
          <span>{heading ? heading : 'Model fields'}</span>
          <Search
            onChange={setSearch}
            onReset={() => setSearch('')}
            defaultRef={searchRef}
            tooltip={
              <p>
                Press <span className="rounded border border-gray-600 bg-gray-700 px-1">/</span> to
                search
              </p>
            }
          />
          <div className="space-x-2">
            {hasWriteinFields && <AddField />}
            {refresh ? (
              <EditPermission>
                <Button onClick={refresh} loading={loading} disabled={disabled} iconEnd="Refresh">
                  Refresh
                </Button>
              </EditPermission>
            ) : (
              <div style={{ width: '105px' }} />
            )}
          </div>
        </TableTopper>
        <FieldsPicker
          fields={fields}
          loading={loading}
          disabled={disabled}
          onFieldsChange={handleFieldsChange}
          hasWriteinFields={hasWriteinFields}
          userTypeSelection={userTypeSelection}
          search={search}
        />
      </TableWrap>
    </div>
  );
}
