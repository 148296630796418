import cx from 'clsx';
import * as React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  Label,
  ModelFieldSelect,
  MyCombobox,
  MyInput,
  ParamButton,
  Section,
  SideBySide
} from '~/components';
import { SchemaForm } from '~/components/common/SchemaForm';
import {
  CosmosDbConfiguration,
  FieldType,
  FilterFunction,
  ModelFieldFragment
} from '~/generated/graphql';
import { useFieldsetState, useModeSwitcher, useModelQueryRef } from '~/hooks';
import {
  FieldsetFormValues,
  ModeConfiguration,
  WINDOW_THRESHOLD,
  hasItems,
  objToSelectables,
  updateFieldsetForm
} from '~/utils';
import { AdditionalConfig } from '../additional-config/additional-config';
import { FieldsTable, ModelQueryEditor } from '../components';

const modeConfiguration: ModeConfiguration<CosmosDbConfiguration> = {
  modes: [
    { name: 'fields', label: 'Field select' },
    { name: 'query', label: 'SQL query' }
  ],
  saveObj: (mode, conf) => ({
    configuration: {
      database: conf.database,
      collection: conf.collection,
      query: mode === 'query' ? conf.query : '',
      filters: mode === 'fields' ? (conf.filters != null ? [...conf.filters] : []) : []
    }
  }),
  reset: conf => ({
    configuration: {
      database: conf.database,
      collection: conf.collection,
      query: '',
      filters: []
    }
  })
};

const queryUpdateObj = (conf: CosmosDbConfiguration | undefined) => ({
  configuration: {
    database: conf?.database || '',
    collection: conf?.collection || '',
    query: conf?.query || '',
    filters: []
  }
});

export function CosmosDbFieldsetConfig() {
  const { fieldset, setFieldset, loading, refreshing, applyUpdate } = useFieldsetState();
  const { control, reset, getValues } = useFormContext<FieldsetFormValues>();

  const handleFieldsUpdate = React.useCallback(() => {
    applyUpdate(
      {
        database: getValues('configuration.database'),
        collection: getValues('configuration.collection'),
        query: '',
        filters: getValues('configuration.filters') || []
      },
      { refresh: true }
    );
  }, [applyUpdate, getValues]);

  const queryCallback = React.useCallback(() => {
    if (!fieldset) {
      return;
    }
    const newFieldset = {
      ...fieldset,
      realName: getValues('realName'),
      fields: [],
      primaryKey: null,
      relatedTo: [],
      configuration: {
        database: getValues('configuration.database'),
        collection: getValues('configuration.collection'),
        query: getValues('configuration.query') || 'select * from c',
        filters: []
      }
    };
    setFieldset(newFieldset);
    updateFieldsetForm(reset, getValues, newFieldset);
  }, [reset, getValues, fieldset, setFieldset]);

  const { modes, mode, handleMode } = useModeSwitcher<CosmosDbConfiguration>(modeConfiguration, {
    fields: handleFieldsUpdate,
    query: queryCallback
  });

  const collection = useWatch({ control, name: 'configuration.collection' });

  const defaultDoc = (fieldset?.configuration as CosmosDbConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);

  const onChange = React.useCallback(
    (config: Record<string, unknown>) => {
      applyUpdate(config, { refresh: true });
    },
    [applyUpdate]
  );

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset?.configuration && fieldset.configurationSchema && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <SchemaForm
                schema={fieldset.configurationSchema}
                values={fieldset.configuration}
                onChange={onChange}
              />
              {collection && (
                <EditPermission
                  fallback={<DisabledSelect className="max-w-xs" valueLabel={modes[mode]} />}
                >
                  <MyCombobox
                    className="max-w-xs"
                    value={{ label: modes[mode], value: mode }}
                    options={objToSelectables(modes, true)}
                    onChange={handleMode}
                  />
                </EditPermission>
              )}
            </div>
          )}
          {collection && mode === 'fields' && fieldset && hasItems(fieldset.fields) && (
            <FilterDocuments fields={fieldset.fields} />
          )}
        </SideBySide>
        {mode === 'query' && (
          <ModelQueryEditor
            heading="SQL query"
            path="configuration.query"
            updateObj={queryUpdateObj}
            defaultDoc={defaultDoc}
            queryRef={queryRef}
          />
        )}
        <FieldsTable
          fields={fieldset?.fields}
          loading={refreshing || loading}
          disabled={loading}
          refresh={mode === 'fields' ? handleFieldsUpdate : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        />
      </Section>
      <AdditionalConfig />
    </>
  );
}

function FilterDocuments(props: { fields: ModelFieldFragment[] }) {
  const { control } = useFormContext<FieldsetFormValues>();

  const {
    fields: filters,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'configuration.filters',
    keyName: 'filterId'
  });

  function handleAdd() {
    append({
      field: undefined,
      function: undefined,
      value: null
    });
  }

  return (
    <div className="mt-3">
      <Label>Filter documents</Label>
      <div className="space-y-1.5">
        {hasItems(filters) ? (
          filters.map((filter, index) => {
            const { filterId } = filter;
            return (
              <div
                key={filterId}
                className="grid w-full grid-cols-[3fr,2fr,3fr,repeat(2,1.25rem)] items-start gap-x-2"
              >
                <label className="thead-text col-span-full -mt-px mb-px block text-gray-500">
                  {index === 0 ? 'WHERE' : 'AND'}
                </label>
                <FilterElements index={index} fields={props.fields} />
                <EditPermission>
                  <ParamButton
                    className="col-start-4 col-end-5 mt-1.75 focus-visible:ring-offset-gray-100"
                    action="delete"
                    onClick={() => remove(index)}
                  />
                </EditPermission>
                <EditPermission>
                  <ParamButton
                    action="add"
                    className={cx(
                      'col-start-5 col-end-6 mt-1.75 focus-visible:ring-offset-gray-100',
                      index === filters?.length - 1 ? 'visible' : 'invisible'
                    )}
                    onClick={handleAdd}
                  />
                </EditPermission>
              </div>
            );
          })
        ) : (
          <EditPermission>
            <ParamButton
              action="add"
              className="focus-visible:ring-offset-gray-100"
              onClick={handleAdd}
            />
          </EditPermission>
        )}
      </div>
    </div>
  );
}

const valueOptions = [
  { value: 'Equality', label: 'equals' },
  { value: 'Inequality', label: 'not equals' }
];

const booleanOptions = [
  { value: 'True', label: 'is true' },
  { value: 'False', label: 'is false' }
];

function FilterElements({ index, fields }: { index: number; fields: ModelFieldFragment[] }) {
  const { control, register, resetField, getValues } = useFormContext<FieldsetFormValues>();

  const [selectedField, setSelectedField] = React.useState<ModelFieldFragment | null>(() => {
    const field = getValues(`configuration.filters.${index}.field`);
    return fields.find(f => f.sourceName === field) || null;
  });

  const filterFunction = useWatch({
    control,
    name: `configuration.filters.${index}.function`
  });

  React.useEffect(() => {
    if (
      filterFunction !== FilterFunction.Equality &&
      filterFunction !== FilterFunction.Inequality
    ) {
      resetField(`configuration.filters.${index}.value`, { defaultValue: null });
    }
    if (
      selectedField?.type === FieldType.Boolean &&
      (filterFunction === FilterFunction.Equality || filterFunction === FilterFunction.Inequality)
    ) {
      resetField(`configuration.filters.${index}.function`, {
        defaultValue: booleanOptions[0].value as FilterFunction
      });
    } else if (
      selectedField?.type !== FieldType.Boolean &&
      (filterFunction === FilterFunction.True || filterFunction === FilterFunction.False)
    ) {
      resetField(`configuration.filters.${index}.function`, {
        defaultValue: valueOptions[0].value as FilterFunction
      });
    }
  }, [filterFunction, index, selectedField, resetField]);

  return (
    <>
      <Controller
        control={control}
        name={`configuration.filters.${index}.field` as const}
        render={({ field }) => {
          return (
            <EditPermission
              fallback={
                <DisabledSelect
                  className="col-start-1 col-end-2"
                  valueLabel={fields.find(opt => opt.sourceName === field.value)?.label}
                />
              }
            >
              <ModelFieldSelect
                className="col-start-1 col-end-2"
                placeholder="Model field..."
                hideGroups
                hideConnections
                isClearable={false}
                options={fields}
                value={fields.find(opt => opt.sourceName === field.value)}
                onChange={selection => {
                  if (!selection) {
                    return;
                  }
                  field.onChange(selection.sourceName);
                  // TODO @poindexd cleanup
                  setSelectedField(selection as ModelFieldFragment);
                }}
                isWindowed={fields.length > WINDOW_THRESHOLD}
              />
            </EditPermission>
          );
        }}
      />
      <Controller
        control={control}
        name={`configuration.filters.${index}.function` as const}
        render={({ field }) => {
          return (
            <EditPermission
              fallback={
                <DisabledSelect
                  className="col-start-2 col-end-3"
                  valueLabel={
                    (selectedField?.type === FieldType.Boolean
                      ? booleanOptions.find(f => f.value === field.value)
                      : valueOptions.find(f => f.value === field.value)
                    )?.label
                  }
                />
              }
            >
              <MyCombobox
                className="col-start-2 col-end-3"
                placeholder="Compare..."
                options={selectedField?.type === FieldType.Boolean ? booleanOptions : valueOptions}
                value={
                  selectedField?.type === FieldType.Boolean
                    ? booleanOptions.find(f => f.value === field.value)
                    : valueOptions.find(f => f.value === field.value)
                }
                onChange={selection => {
                  if (!selection) {
                    return;
                  }
                  field.onChange(selection.value);
                }}
              />
            </EditPermission>
          );
        }}
      />
      <div className="col-start-3 col-end-4">
        {(filterFunction === FilterFunction.Equality ||
          filterFunction === FilterFunction.Inequality) && (
          <EditPermission>
            <MyInput
              {...register(`configuration.filters.${index}.value` as const)}
              placeholder="Value..."
            />
          </EditPermission>
        )}
      </div>
    </>
  );
}
